@import '../_variables.scss';

#stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow:  812px  853px $white,  760px  113px $white,  500px 1057px $white, 1092px 1385px $white, 1450px 1921px $white, 1502px  789px $white, 
                 675px 1434px $white,  922px 1882px $white,  429px 1420px $white,  384px  255px $white, 1219px  515px $white,   18px  922px $white, 
                1890px  265px $white,  744px  999px $white,  634px  996px $white,  706px  163px $white,  546px  917px $white,  266px  733px $white, 
                1332px 1756px $white, 1355px  910px $white, 1048px 1658px $white, 1334px  713px $white,  637px  259px $white,  137px  491px $white, 
                1818px 1581px $white,   44px  579px $white, 1352px  355px $white,  886px 1394px $white, 1583px  614px $white, 1145px  522px $white, 
                 250px  764px $white, 1981px 1115px $white,  846px 1008px $white, 1714px  188px $white,  583px 1564px $white, 1747px  182px $white, 
                1867px  537px $white,  216px 1335px $white,  714px 1739px $white, 1477px  123px $white, 1161px 1367px $white, 1614px  458px $white, 
                1104px 1147px $white,  558px  815px $white, 1382px 1259px $white, 1449px  616px $white, 1440px 1884px $white, 1702px 1073px $white, 
                1339px 1055px $white, 1958px  138px $white,  702px  200px $white, 1966px 1595px $white, 1527px  701px $white, 1956px 1297px $white, 
                1542px 1868px $white, 1697px  448px $white,  988px 1705px $white,  668px   26px $white,  405px 1954px $white,  724px   50px $white, 
                1172px 1230px $white,  215px 1342px $white,  309px   32px $white, 1761px 1864px $white,  270px  887px $white,  766px  825px $white, 
                1240px 1691px $white,  578px 1337px $white, 1025px 1764px $white,  805px 1320px $white,  256px 1319px $white, 1500px  746px $white, 
                1017px 1700px $white,  655px 1972px $white, 1626px  311px $white,  737px 1386px $white,  381px 1862px $white, 1361px 1281px $white, 
                 294px 1795px $white, 1253px 1778px $white,  118px  921px $white,  894px 1459px $white,  830px 1693px $white, 1440px 1741px $white, 
                1888px  162px $white, 1441px  724px $white, 1257px 1076px $white,  272px 1597px $white, 1810px 1252px $white, 1025px  545px $white, 
                1671px 1103px $white, 1482px 1879px $white, 1891px  367px $white, 1693px  850px $white,   71px  484px $white,  578px  952px $white, 
                1132px  973px $white, 1531px 1785px $white, 1836px 1430px $white, 1562px  141px $white,  398px 1402px $white,  366px 1519px $white, 
                1572px  614px $white, 1839px 1257px $white, 1042px 1680px $white, 1081px  262px $white,  693px   77px $white,  375px  481px $white, 
                1033px 1386px $white, 1505px  479px $white,  523px  423px $white, 1714px 1961px $white, 1168px 1043px $white, 1232px  770px $white, 
                1206px  178px $white, 1125px 1189px $white, 1655px 1873px $white,   38px  794px $white,  140px 1625px $white, 1608px 1392px $white, 
                 440px 1150px $white,  339px  380px $white, 1266px 1868px $white, 1978px 1161px $white, 1167px  826px $white, 1406px 1370px $white, 
                1738px   72px $white, 1610px  797px $white,  520px 1428px $white,  464px  511px $white, 1932px  379px $white, 1122px  326px $white, 
                 903px 1621px $white,  379px 1936px $white,  621px 1105px $white, 1635px  980px $white,  832px 1416px $white,  790px 1568px $white, 
                 383px  867px $white,  775px 1774px $white,  418px  168px $white,  397px 1738px $white,  570px  192px $white, 1576px  191px $white, 
                 714px 1587px $white, 1388px 1120px $white, 1524px 1794px $white,  884px 1543px $white,  939px  246px $white,   93px  708px $white, 
                1948px 1979px $white, 1080px  785px $white,  277px 1189px $white, 1714px 1572px $white,  775px 1222px $white, 1049px 1416px $white, 
                   2px  958px $white,  509px 1009px $white,  931px  180px $white,  194px 1112px $white, 1685px  947px $white,  498px  578px $white, 
                 930px 1409px $white,  178px  910px $white,  898px  766px $white, 1736px  806px $white,  155px  194px $white, 1658px  677px $white, 
                1527px 1685px $white,  955px 1342px $white, 1485px  279px $white, 1157px  662px $white, 1999px 1335px $white,  152px  487px $white, 
                1816px  576px $white, 1882px  858px $white, 1764px 1814px $white,  196px 1040px $white, 1583px  140px $white, 1489px 1706px $white, 
                 807px 1092px $white,  418px  751px $white, 1809px  466px $white,  445px   72px $white,  469px 1107px $white,  236px 1413px $white, 
                1763px 1922px $white,  483px 1081px $white,  964px  652px $white, 1708px  785px $white, 1239px    1px $white,  582px 1748px $white, 
                  67px 1765px $white, 1715px 1667px $white,  775px 1245px $white, 1232px 1839px $white, 1645px  307px $white,   65px 1755px $white, 
                 121px 1478px $white,   69px  907px $white,  890px 1773px $white,  645px 1574px $white, 1299px 1602px $white, 1390px  177px $white, 
                1342px  120px $white, 1971px 1303px $white, 1854px 1577px $white,  972px  582px $white, 1361px 1358px $white, 1783px  407px $white, 
                1654px  763px $white, 1472px 1351px $white,    8px 1723px $white,  811px 1606px $white, 1716px 1202px $white,   73px  124px $white, 
                 891px  678px $white, 1820px 1909px $white,  433px 1516px $white,  700px 1222px $white, 1134px  156px $white,   20px  794px $white, 
                1621px 1093px $white, 1127px 1672px $white, 1101px    5px $white, 1590px  423px $white,  734px 1174px $white,  687px 1317px $white, 
                 440px 1548px $white,   90px   12px $white,  163px 1506px $white,  283px 1267px $white, 1744px 1536px $white, 1199px 1443px $white, 
                1790px  110px $white,  490px 1419px $white, 1949px  238px $white,  527px  812px $white,  689px  701px $white, 1674px   24px $white, 
                 316px 1730px $white,  189px  661px $white, 1648px  755px $white,  838px 1961px $white,  880px  928px $white, 1256px 1799px $white, 
                1355px 1258px $white, 1477px  157px $white, 1586px   42px $white, 1998px 1267px $white, 1642px 1803px $white, 1136px 1502px $white, 
                1063px 1157px $white,  377px  635px $white, 1200px 1281px $white, 1179px  934px $white,  115px  353px $white,  656px 1789px $white, 
                 626px 1964px $white, 1919px 1720px $white,  345px 1085px $white,  959px  189px $white, 1571px  119px $white, 1516px 1562px $white, 
                 859px 1596px $white,  130px 1673px $white, 1569px  738px $white, 1916px 1112px $white, 1104px 1347px $white,  572px  913px $white, 
                1015px  167px $white,  666px 1245px $white, 1823px  924px $white, 1132px  951px $white,  284px 1415px $white,  330px 1601px $white, 
                 289px  342px $white, 1948px  665px $white, 1476px  472px $white, 1886px 1225px $white, 1673px  568px $white, 1688px 1657px $white, 
                1714px 1720px $white,  522px  887px $white, 1523px 1132px $white, 1089px  201px $white, 1086px  253px $white, 1718px 1167px $white, 
                1129px 1196px $white, 1516px 1990px $white,  353px  422px $white, 1965px 1358px $white,  761px 1305px $white,  660px 1651px $white, 
                 306px 1304px $white, 1460px 1480px $white,  639px  878px $white,  153px  309px $white, 1743px 1584px $white,  430px  891px $white, 
                1845px  598px $white,  410px  173px $white,  312px 1798px $white,  898px 1291px $white,  542px 1527px $white, 1037px  258px $white, 
                1370px  554px $white, 1811px 1751px $white, 1306px 1825px $white, 1270px 1801px $white, 1327px 1850px $white,  238px 1759px $white, 
                1502px 1632px $white, 1244px 1605px $white, 1329px   19px $white,  850px 1722px $white,  701px   26px $white, 1436px   67px $white, 
                 105px 1773px $white, 1956px  681px $white,  717px  525px $white,  537px  296px $white,  349px  760px $white, 1017px 1895px $white, 
                1219px 1478px $white, 1880px 1910px $white, 1950px  289px $white, 1249px  500px $white,  562px 1713px $white, 1265px  858px $white, 
                  69px 1156px $white, 1942px  116px $white,  856px 1002px $white, 1657px  207px $white, 1131px   65px $white,  665px 1248px $white, 
                 313px  184px $white,  914px   80px $white, 1746px  888px $white, 1340px 1741px $white,    1px  658px $white,  919px 1607px $white, 
                 595px 1191px $white, 1279px  192px $white,  636px  390px $white, 1770px  868px $white, 1344px  985px $white, 1410px 1608px $white, 
                 548px 1635px $white,  968px 1308px $white,  692px   13px $white,  837px 1202px $white,  958px  447px $white,  494px 1047px $white, 
                  94px   62px $white,  942px 1951px $white, 1615px  454px $white,  450px  652px $white, 1760px  535px $white,  494px 1101px $white, 
                 443px  833px $white,   75px 1080px $white,  284px   13px $white,   35px  284px $white,  896px 1360px $white,  113px  290px $white, 
                 285px  949px $white,  700px 1121px $white, 1057px  458px $white, 1488px 1179px $white, 1156px  153px $white,  446px 1811px $white, 
                1303px 1117px $white, 1630px 1290px $white, 1925px  298px $white,  212px  943px $white, 1007px  877px $white,  912px   60px $white, 
                 464px  759px $white,  295px 1439px $white, 1463px 1172px $white, 1485px  853px $white,  190px  518px $white, 1576px  341px $white, 
                1568px  516px $white, 1994px  482px $white,  763px  639px $white, 1928px 1753px $white, 1350px 1197px $white, 1369px 1087px $white, 
                 738px 1691px $white, 1396px  310px $white, 1749px  236px $white,  289px  768px $white, 1804px 1824px $white,  968px  433px $white, 
                1860px  528px $white,  906px  539px $white, 1777px 1875px $white,  164px 1027px $white, 1501px 1192px $white,  597px  765px $white, 
                1511px   49px $white, 1232px 1047px $white, 1696px  644px $white,  152px  617px $white,  812px 1189px $white,  734px   61px $white, 
                 193px  136px $white, 1753px  409px $white, 1482px 1420px $white,    2px 1542px $white,  753px  302px $white, 1179px 1838px $white, 
                1541px  706px $white, 1701px 1234px $white,  613px 1392px $white, 1877px 1223px $white, 1355px   14px $white,   45px  748px $white, 
                1054px  570px $white, 1024px 1085px $white,  723px    9px $white,  231px  578px $white, 1862px  864px $white,   11px  898px $white, 
                1788px  767px $white,  701px  869px $white,  371px   24px $white,  320px  278px $white, 1627px 1841px $white, 1224px  848px $white, 
                 836px  477px $white,  755px 1311px $white,  987px 1506px $white,  817px  311px $white, 1781px 1900px $white,   20px 1870px $white, 
                 630px  149px $white,  412px 1812px $white, 1926px  811px $white,  853px  642px $white,  743px  952px $white,  385px 1792px $white, 
                1988px  314px $white, 1882px 1035px $white, 1722px  544px $white, 1265px 1423px $white,  726px  123px $white,  351px 1003px $white, 
                 432px  357px $white, 1732px  710px $white,   67px  335px $white,  453px  372px $white, 1093px 1872px $white, 1924px  758px $white, 
                  54px  587px $white, 1892px  466px $white,  811px   96px $white,  801px  470px $white,  341px 1784px $white, 1641px  566px $white, 
                 888px  890px $white,   76px  904px $white, 1570px  487px $white,  877px 1327px $white,  240px 1658px $white,   32px 1333px $white, 
                 615px 1047px $white, 1492px 1289px $white, 1332px 1820px $white,  818px  481px $white,  162px 1655px $white,  588px 1674px $white, 
                 268px  628px $white,  214px 1472px $white, 1608px  827px $white,   85px 1922px $white,  958px  541px $white,  367px 1775px $white, 
                 452px 1768px $white,  675px  709px $white,  431px 1552px $white, 1087px 1685px $white, 1943px  729px $white, 1648px  870px $white, 
                1761px  662px $white, 1951px  884px $white, 1777px 1338px $white,  164px 1225px $white,   29px  965px $white, 1504px  946px $white, 
                1020px 1691px $white,   31px 1698px $white,  861px 1896px $white, 1064px 1486px $white,  316px  504px $white,  272px  763px $white, 
                 351px 1611px $white, 1925px  420px $white,  586px 1774px $white, 1895px 1289px $white,  287px  596px $white, 1062px 1993px $white, 
                   1px 1128px $white, 1251px 1326px $white, 1511px 1608px $white,  189px 1917px $white, 1563px 1186px $white,   66px  413px $white, 
                 713px   47px $white, 1085px   66px $white, 1217px 1637px $white, 1374px  506px $white,  180px  809px $white, 1375px 1201px $white, 
                 452px 1172px $white, 1493px   85px $white, 1852px  618px $white,  241px 1742px $white, 1727px 1121px $white, 1294px 1045px $white, 
                 536px  798px $white,  917px  911px $white,  520px 1771px $white, 1814px  534px $white, 1107px 1877px $white, 1047px  703px $white, 
                 136px  658px $white, 1077px  259px $white,   22px 1091px $white,  632px   38px $white, 1734px 1740px $white, 1920px 1414px $white, 
                 210px 1842px $white, 1252px  308px $white, 1343px 1253px $white, 1960px 1110px $white,  768px  803px $white, 1930px 1479px $white, 
                 698px  802px $white, 1721px 1417px $white, 1970px  811px $white,  171px 1837px $white, 1430px  354px $white, 1543px 1896px $white, 
                1335px 1860px $white, 1384px  732px $white,  656px  592px $white, 1844px 1066px $white,  545px  487px $white, 1034px   60px $white, 
                 702px   95px $white,  877px  733px $white, 1408px 1931px $white,  316px 1222px $white,  318px 1408px $white,  444px 1609px $white, 
                1603px 1306px $white,  200px  212px $white, 1270px  649px $white,  813px  212px $white, 1954px  405px $white, 1488px  534px $white, 
                1775px 1224px $white, 1876px  127px $white, 1768px 1505px $white,  444px  794px $white,  185px 1332px $white, 1203px  645px $white, 
                 323px 1041px $white, 1664px 1659px $white,  586px 1329px $white,  794px 1294px $white,  497px 1406px $white,  125px  746px $white, 
                1392px  725px $white, 1712px  508px $white, 1184px  382px $white,  127px 1403px $white,  321px 1534px $white,  279px  366px $white, 
                 201px 1057px $white,  733px  330px $white,  155px 1299px $white,  198px 1243px $white,  754px  585px $white, 1625px  558px $white, 
                1232px 1389px $white,  643px  619px $white, 1968px  489px $white, 1139px 1093px $white,  881px  103px $white,  704px   85px $white, 
                 327px  366px $white, 1985px 1832px $white,  193px 1735px $white, 1770px 1836px $white,  387px  978px $white, 1525px  744px $white, 
                 221px 1033px $white,  897px 1513px $white,  695px  921px $white, 1469px 1346px $white,  735px   66px $white,  871px 1786px $white, 
                1935px 1071px $white,  319px  345px $white, 1364px  801px $white, 1148px 1769px $white,  832px 1536px $white,  952px  787px $white, 
                 987px  988px $white, 1755px  703px $white, 1741px 1997px $white, 1031px 1937px $white,  773px   93px $white, 1558px 1588px $white, 
                1221px  128px $white,  716px  925px $white, 1262px 1764px $white, 1903px 1753px $white,  528px  231px $white, 1686px 1612px $white, 
                1751px 1418px $white,  352px  616px $white,  320px  991px $white, 1752px 1797px $white, 1700px  278px $white,  551px 1065px $white, 
                 858px 1613px $white,  138px  533px $white, 1792px  629px $white, 1296px   25px $white, 1589px  673px $white, 1681px  881px $white, 
                 537px  401px $white,  167px 1822px $white, 1162px 1793px $white,  333px 1752px $white, 1949px  392px $white,  233px 1951px $white, 
                 940px 1000px $white,  685px 1058px $white, 1087px 1115px $white, 1970px 1454px $white,  140px  277px $white, 1006px 1253px $white, 
                 323px   27px $white, 1925px 1308px $white,  783px 1068px $white,  677px 1269px $white, 1929px 1577px $white,  134px 1142px $white, 
                1183px  162px $white,   34px 1570px $white,  276px  929px $white,  784px 1040px $white, 1586px 1617px $white,  706px  875px $white, 
                1101px 1622px $white, 1269px 1886px $white,   71px 1982px $white, 1767px 1242px $white,  100px 1694px $white, 1148px 1536px $white, 
                1755px 1655px $white, 1141px  199px $white, 1375px  472px $white,  550px 1098px $white, 1417px  514px $white, 1376px  522px $white, 
                 382px 1348px $white,  609px 1228px $white, 1508px 1005px $white,  435px 1374px $white, 1033px  129px $white, 1267px 1967px $white, 
                1769px 1876px $white, 1034px 1841px $white, 1473px 1968px $white,  298px 1818px $white, 1506px 1502px $white, 1970px 1962px $white, 
                 642px  616px $white,  904px 1189px $white,  526px 1898px $white, 1052px 1718px $white, 1962px   99px $white, 1720px  608px $white, 
                 728px 1922px $white, 1373px  686px $white, 1942px  744px $white, 1483px  137px $white, 1846px  156px $white,  124px 1596px $white, 
                 133px  144px $white,  562px 1348px $white,  473px  188px $white, 1710px  771px $white;
    animation: animStar 50s linear infinite
}

#stars:after {
    content: " ";
    position: absolute;
    top: 1250px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow:  812px  853px $white,  760px  113px $white,  500px 1057px $white, 1092px 1385px $white, 1450px 1921px $white, 1502px  789px $white, 
                 675px 1434px $white,  922px 1882px $white,  429px 1420px $white,  384px  255px $white, 1219px  515px $white,   18px  922px $white, 
                1890px  265px $white,  744px  999px $white,  634px  996px $white,  706px  163px $white,  546px  917px $white,  266px  733px $white, 
                1332px 1756px $white, 1355px  910px $white, 1048px 1658px $white, 1334px  713px $white,  637px  259px $white,  137px  491px $white, 
                1818px 1581px $white,   44px  579px $white, 1352px  355px $white,  886px 1394px $white, 1583px  614px $white, 1145px  522px $white, 
                 250px  764px $white, 1981px 1115px $white,  846px 1008px $white, 1714px  188px $white,  583px 1564px $white, 1747px  182px $white, 
                1867px  537px $white,  216px 1335px $white,  714px 1739px $white, 1477px  123px $white, 1161px 1367px $white, 1614px  458px $white, 
                1104px 1147px $white,  558px  815px $white, 1382px 1259px $white, 1449px  616px $white, 1440px 1884px $white, 1702px 1073px $white, 
                1339px 1055px $white, 1958px  138px $white,  702px  200px $white, 1966px 1595px $white, 1527px  701px $white, 1956px 1297px $white, 
                1542px 1868px $white, 1697px  448px $white,  988px 1705px $white,  668px   26px $white,  405px 1954px $white,  724px   50px $white, 
                1172px 1230px $white,  215px 1342px $white,  309px   32px $white, 1761px 1864px $white,  270px  887px $white,  766px  825px $white, 
                1240px 1691px $white,  578px 1337px $white, 1025px 1764px $white,  805px 1320px $white,  256px 1319px $white, 1500px  746px $white, 
                1017px 1700px $white,  655px 1972px $white, 1626px  311px $white,  737px 1386px $white,  381px 1862px $white, 1361px 1281px $white, 
                 294px 1795px $white, 1253px 1778px $white,  118px  921px $white,  894px 1459px $white,  830px 1693px $white, 1440px 1741px $white, 
                1888px  162px $white, 1441px  724px $white, 1257px 1076px $white,  272px 1597px $white, 1810px 1252px $white, 1025px  545px $white, 
                1671px 1103px $white, 1482px 1879px $white, 1891px  367px $white, 1693px  850px $white,   71px  484px $white,  578px  952px $white, 
                1132px  973px $white, 1531px 1785px $white, 1836px 1430px $white, 1562px  141px $white,  398px 1402px $white,  366px 1519px $white, 
                1572px  614px $white, 1839px 1257px $white, 1042px 1680px $white, 1081px  262px $white,  693px   77px $white,  375px  481px $white, 
                1033px 1386px $white, 1505px  479px $white,  523px  423px $white, 1714px 1961px $white, 1168px 1043px $white, 1232px  770px $white, 
                1206px  178px $white, 1125px 1189px $white, 1655px 1873px $white,   38px  794px $white,  140px 1625px $white, 1608px 1392px $white, 
                 440px 1150px $white,  339px  380px $white, 1266px 1868px $white, 1978px 1161px $white, 1167px  826px $white, 1406px 1370px $white, 
                1738px   72px $white, 1610px  797px $white,  520px 1428px $white,  464px  511px $white, 1932px  379px $white, 1122px  326px $white, 
                 903px 1621px $white,  379px 1936px $white,  621px 1105px $white, 1635px  980px $white,  832px 1416px $white,  790px 1568px $white, 
                 383px  867px $white,  775px 1774px $white,  418px  168px $white,  397px 1738px $white,  570px  192px $white, 1576px  191px $white, 
                 714px 1587px $white, 1388px 1120px $white, 1524px 1794px $white,  884px 1543px $white,  939px  246px $white,   93px  708px $white, 
                1948px 1979px $white, 1080px  785px $white,  277px 1189px $white, 1714px 1572px $white,  775px 1222px $white, 1049px 1416px $white, 
                   2px  958px $white,  509px 1009px $white,  931px  180px $white,  194px 1112px $white, 1685px  947px $white,  498px  578px $white, 
                 930px 1409px $white,  178px  910px $white,  898px  766px $white, 1736px  806px $white,  155px  194px $white, 1658px  677px $white, 
                1527px 1685px $white,  955px 1342px $white, 1485px  279px $white, 1157px  662px $white, 1999px 1335px $white,  152px  487px $white, 
                1816px  576px $white, 1882px  858px $white, 1764px 1814px $white,  196px 1040px $white, 1583px  140px $white, 1489px 1706px $white, 
                 807px 1092px $white,  418px  751px $white, 1809px  466px $white,  445px   72px $white,  469px 1107px $white,  236px 1413px $white, 
                1763px 1922px $white,  483px 1081px $white,  964px  652px $white, 1708px  785px $white, 1239px    1px $white,  582px 1748px $white, 
                  67px 1765px $white, 1715px 1667px $white,  775px 1245px $white, 1232px 1839px $white, 1645px  307px $white,   65px 1755px $white, 
                 121px 1478px $white,   69px  907px $white,  890px 1773px $white,  645px 1574px $white, 1299px 1602px $white, 1390px  177px $white, 
                1342px  120px $white, 1971px 1303px $white, 1854px 1577px $white,  972px  582px $white, 1361px 1358px $white, 1783px  407px $white, 
                1654px  763px $white, 1472px 1351px $white,    8px 1723px $white,  811px 1606px $white, 1716px 1202px $white,   73px  124px $white, 
                 891px  678px $white, 1820px 1909px $white,  433px 1516px $white,  700px 1222px $white, 1134px  156px $white,   20px  794px $white, 
                1621px 1093px $white, 1127px 1672px $white, 1101px    5px $white, 1590px  423px $white,  734px 1174px $white,  687px 1317px $white, 
                 440px 1548px $white,   90px   12px $white,  163px 1506px $white,  283px 1267px $white, 1744px 1536px $white, 1199px 1443px $white, 
                1790px  110px $white,  490px 1419px $white, 1949px  238px $white,  527px  812px $white,  689px  701px $white, 1674px   24px $white, 
                 316px 1730px $white,  189px  661px $white, 1648px  755px $white,  838px 1961px $white,  880px  928px $white, 1256px 1799px $white, 
                1355px 1258px $white, 1477px  157px $white, 1586px   42px $white, 1998px 1267px $white, 1642px 1803px $white, 1136px 1502px $white, 
                1063px 1157px $white,  377px  635px $white, 1200px 1281px $white, 1179px  934px $white,  115px  353px $white,  656px 1789px $white, 
                 626px 1964px $white, 1919px 1720px $white,  345px 1085px $white,  959px  189px $white, 1571px  119px $white, 1516px 1562px $white, 
                 859px 1596px $white,  130px 1673px $white, 1569px  738px $white, 1916px 1112px $white, 1104px 1347px $white,  572px  913px $white, 
                1015px  167px $white,  666px 1245px $white, 1823px  924px $white, 1132px  951px $white,  284px 1415px $white,  330px 1601px $white, 
                 289px  342px $white, 1948px  665px $white, 1476px  472px $white, 1886px 1225px $white, 1673px  568px $white, 1688px 1657px $white, 
                1714px 1720px $white,  522px  887px $white, 1523px 1132px $white, 1089px  201px $white, 1086px  253px $white, 1718px 1167px $white, 
                1129px 1196px $white, 1516px 1990px $white,  353px  422px $white, 1965px 1358px $white,  761px 1305px $white,  660px 1651px $white, 
                 306px 1304px $white, 1460px 1480px $white,  639px  878px $white,  153px  309px $white, 1743px 1584px $white,  430px  891px $white, 
                1845px  598px $white,  410px  173px $white,  312px 1798px $white,  898px 1291px $white,  542px 1527px $white, 1037px  258px $white, 
                1370px  554px $white, 1811px 1751px $white, 1306px 1825px $white, 1270px 1801px $white, 1327px 1850px $white,  238px 1759px $white, 
                1502px 1632px $white, 1244px 1605px $white, 1329px   19px $white,  850px 1722px $white,  701px   26px $white, 1436px   67px $white, 
                 105px 1773px $white, 1956px  681px $white,  717px  525px $white,  537px  296px $white,  349px  760px $white, 1017px 1895px $white, 
                1219px 1478px $white, 1880px 1910px $white, 1950px  289px $white, 1249px  500px $white,  562px 1713px $white, 1265px  858px $white, 
                  69px 1156px $white, 1942px  116px $white,  856px 1002px $white, 1657px  207px $white, 1131px   65px $white,  665px 1248px $white, 
                 313px  184px $white,  914px   80px $white, 1746px  888px $white, 1340px 1741px $white,    1px  658px $white,  919px 1607px $white, 
                 595px 1191px $white, 1279px  192px $white,  636px  390px $white, 1770px  868px $white, 1344px  985px $white, 1410px 1608px $white, 
                 548px 1635px $white,  968px 1308px $white,  692px   13px $white,  837px 1202px $white,  958px  447px $white,  494px 1047px $white, 
                  94px   62px $white,  942px 1951px $white, 1615px  454px $white,  450px  652px $white, 1760px  535px $white,  494px 1101px $white, 
                 443px  833px $white,   75px 1080px $white,  284px   13px $white,   35px  284px $white,  896px 1360px $white,  113px  290px $white, 
                 285px  949px $white,  700px 1121px $white, 1057px  458px $white, 1488px 1179px $white, 1156px  153px $white,  446px 1811px $white, 
                1303px 1117px $white, 1630px 1290px $white, 1925px  298px $white,  212px  943px $white, 1007px  877px $white,  912px   60px $white, 
                 464px  759px $white,  295px 1439px $white, 1463px 1172px $white, 1485px  853px $white,  190px  518px $white, 1576px  341px $white, 
                1568px  516px $white, 1994px  482px $white,  763px  639px $white, 1928px 1753px $white, 1350px 1197px $white, 1369px 1087px $white, 
                 738px 1691px $white, 1396px  310px $white, 1749px  236px $white,  289px  768px $white, 1804px 1824px $white,  968px  433px $white, 
                1860px  528px $white,  906px  539px $white, 1777px 1875px $white,  164px 1027px $white, 1501px 1192px $white,  597px  765px $white, 
                1511px   49px $white, 1232px 1047px $white, 1696px  644px $white,  152px  617px $white,  812px 1189px $white,  734px   61px $white, 
                 193px  136px $white, 1753px  409px $white, 1482px 1420px $white,    2px 1542px $white,  753px  302px $white, 1179px 1838px $white, 
                1541px  706px $white, 1701px 1234px $white,  613px 1392px $white, 1877px 1223px $white, 1355px   14px $white,   45px  748px $white, 
                1054px  570px $white, 1024px 1085px $white,  723px    9px $white,  231px  578px $white, 1862px  864px $white,   11px  898px $white, 
                1788px  767px $white,  701px  869px $white,  371px   24px $white,  320px  278px $white, 1627px 1841px $white, 1224px  848px $white, 
                 836px  477px $white,  755px 1311px $white,  987px 1506px $white,  817px  311px $white, 1781px 1900px $white,   20px 1870px $white, 
                 630px  149px $white,  412px 1812px $white, 1926px  811px $white,  853px  642px $white,  743px  952px $white,  385px 1792px $white, 
                1988px  314px $white, 1882px 1035px $white, 1722px  544px $white, 1265px 1423px $white,  726px  123px $white,  351px 1003px $white, 
                 432px  357px $white, 1732px  710px $white,   67px  335px $white,  453px  372px $white, 1093px 1872px $white, 1924px  758px $white, 
                  54px  587px $white, 1892px  466px $white,  811px   96px $white,  801px  470px $white,  341px 1784px $white, 1641px  566px $white, 
                 888px  890px $white,   76px  904px $white, 1570px  487px $white,  877px 1327px $white,  240px 1658px $white,   32px 1333px $white, 
                 615px 1047px $white, 1492px 1289px $white, 1332px 1820px $white,  818px  481px $white,  162px 1655px $white,  588px 1674px $white, 
                 268px  628px $white,  214px 1472px $white, 1608px  827px $white,   85px 1922px $white,  958px  541px $white,  367px 1775px $white, 
                 452px 1768px $white,  675px  709px $white,  431px 1552px $white, 1087px 1685px $white, 1943px  729px $white, 1648px  870px $white, 
                1761px  662px $white, 1951px  884px $white, 1777px 1338px $white,  164px 1225px $white,   29px  965px $white, 1504px  946px $white, 
                1020px 1691px $white,   31px 1698px $white,  861px 1896px $white, 1064px 1486px $white,  316px  504px $white,  272px  763px $white, 
                 351px 1611px $white, 1925px  420px $white,  586px 1774px $white, 1895px 1289px $white,  287px  596px $white, 1062px 1993px $white, 
                   1px 1128px $white, 1251px 1326px $white, 1511px 1608px $white,  189px 1917px $white, 1563px 1186px $white,   66px  413px $white, 
                 713px   47px $white, 1085px   66px $white, 1217px 1637px $white, 1374px  506px $white,  180px  809px $white, 1375px 1201px $white, 
                 452px 1172px $white, 1493px   85px $white, 1852px  618px $white,  241px 1742px $white, 1727px 1121px $white, 1294px 1045px $white, 
                 536px  798px $white,  917px  911px $white,  520px 1771px $white, 1814px  534px $white, 1107px 1877px $white, 1047px  703px $white, 
                 136px  658px $white, 1077px  259px $white,   22px 1091px $white,  632px   38px $white, 1734px 1740px $white, 1920px 1414px $white,
                 210px 1842px $white, 1252px  308px $white, 1343px 1253px $white, 1960px 1110px $white,  768px  803px $white, 1930px 1479px $white, 
                 698px  802px $white, 1721px 1417px $white, 1970px  811px $white,  171px 1837px $white, 1430px  354px $white, 1543px 1896px $white, 
                1335px 1860px $white, 1384px  732px $white,  656px  592px $white, 1844px 1066px $white,  545px  487px $white, 1034px   60px $white, 
                 702px   95px $white,  877px  733px $white, 1408px 1931px $white,  316px 1222px $white,  318px 1408px $white,  444px 1609px $white, 
                1603px 1306px $white,  200px  212px $white, 1270px  649px $white,  813px  212px $white, 1954px  405px $white, 1488px  534px $white, 
                1775px 1224px $white, 1876px  127px $white, 1768px 1505px $white,  444px  794px $white,  185px 1332px $white, 1203px  645px $white, 
                 323px 1041px $white, 1664px 1659px $white,  586px 1329px $white,  794px 1294px $white,  497px 1406px $white,  125px  746px $white, 
                1392px  725px $white, 1712px  508px $white, 1184px  382px $white,  127px 1403px $white,  321px 1534px $white,  279px  366px $white, 
                 201px 1057px $white,  733px  330px $white,  155px 1299px $white,  198px 1243px $white,  754px  585px $white, 1625px  558px $white, 
                1232px 1389px $white,  643px  619px $white, 1968px  489px $white, 1139px 1093px $white,  881px  103px $white,  704px   85px $white, 
                 327px  366px $white, 1985px 1832px $white,  193px 1735px $white, 1770px 1836px $white,  387px  978px $white, 1525px  744px $white, 
                 221px 1033px $white,  897px 1513px $white,  695px  921px $white, 1469px 1346px $white,  735px   66px $white,  871px 1786px $white, 
                1935px 1071px $white,  319px  345px $white, 1364px  801px $white, 1148px 1769px $white,  832px 1536px $white,  952px  787px $white, 
                 987px  988px $white, 1755px  703px $white, 1741px 1997px $white, 1031px 1937px $white,  773px   93px $white, 1558px 1588px $white, 
                1221px  128px $white,  716px  925px $white, 1262px 1764px $white, 1903px 1753px $white,  528px  231px $white, 1686px 1612px $white, 
                1751px 1418px $white,  352px  616px $white,  320px  991px $white, 1752px 1797px $white, 1700px  278px $white,  551px 1065px $white, 
                 858px 1613px $white,  138px  533px $white, 1792px  629px $white, 1296px   25px $white, 1589px  673px $white, 1681px  881px $white, 
                 537px  401px $white,  167px 1822px $white, 1162px 1793px $white,  333px 1752px $white, 1949px  392px $white,  233px 1951px $white, 
                 940px 1000px $white,  685px 1058px $white, 1087px 1115px $white, 1970px 1454px $white,  140px  277px $white, 1006px 1253px $white, 
                 323px   27px $white, 1925px 1308px $white,  783px 1068px $white,  677px 1269px $white, 1929px 1577px $white,  134px 1142px $white, 
                1183px  162px $white,   34px 1570px $white,  276px  929px $white,  784px 1040px $white, 1586px 1617px $white,  706px  875px $white, 
                1101px 1622px $white, 1269px 1886px $white,   71px 1982px $white, 1767px 1242px $white,  100px 1694px $white, 1148px 1536px $white, 
                1755px 1655px $white, 1141px  199px $white, 1375px  472px $white,  550px 1098px $white, 1417px  514px $white, 1376px  522px $white, 
                 382px 1348px $white,  609px 1228px $white, 1508px 1005px $white,  435px 1374px $white, 1033px  129px $white, 1267px 1967px $white, 
                1769px 1876px $white, 1034px 1841px $white, 1473px 1968px $white,  298px 1818px $white, 1506px 1502px $white, 1970px 1962px $white, 
                 642px  616px $white,  904px 1189px $white,  526px 1898px $white, 1052px 1718px $white, 1962px   99px $white, 1720px  608px $white, 
                 728px 1922px $white, 1373px  686px $white, 1942px  744px $white, 1483px  137px $white, 1846px  156px $white,  124px 1596px $white, 
                 133px  144px $white,  562px 1348px $white,  473px  188px $white, 1710px  771px $white;
}

#stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1481px  608px $white,  717px  101px $white,  890px 1721px $white, 1767px 1949px $white, 1864px 1216px $white, 1514px  849px $white, 
                 742px 1068px $white,  230px  663px $white, 1261px  828px $white, 1211px  710px $white,  111px 1322px $white,  483px 1159px $white, 
                1835px  314px $white,  251px 1900px $white, 1204px 1177px $white, 1550px 1256px $white,  386px 1732px $white, 1729px  142px $white, 
                  30px  994px $white,  488px 1637px $white,   77px  523px $white,  470px 1521px $white,  645px 1839px $white,  702px 1859px $white, 
                1582px  507px $white,  145px  445px $white, 1070px 1066px $white,  664px 1652px $white, 1530px  326px $white, 1820px 1922px $white, 
                 776px  273px $white, 1873px  135px $white, 1715px  789px $white, 1900px  989px $white, 1438px  508px $white, 1445px  893px $white, 
                 651px 1010px $white, 1250px 1777px $white, 1077px  509px $white, 1241px 1597px $white,  773px 1811px $white,   12px 1347px $white, 
                1805px 1939px $white,   53px 1847px $white,  662px 1130px $white,  902px  571px $white,  533px  393px $white, 1155px  435px $white, 
                1201px  882px $white,  709px  825px $white,  156px 1046px $white, 1805px  736px $white, 1035px  737px $white, 1695px  308px $white, 
                1888px 1334px $white,   97px  675px $white, 1168px  276px $white,  594px  701px $white, 1585px 1338px $white,  532px   34px $white, 
                1096px 1999px $white,    5px  867px $white, 1237px  976px $white,  618px 1314px $white, 1385px 1915px $white,  265px 1552px $white, 
                1347px 1123px $white, 1364px 1534px $white, 1817px 1559px $white, 1014px  456px $white, 1164px 1946px $white, 1600px  257px $white, 
                1726px 1826px $white,  258px 1207px $white,    6px 1718px $white,  611px 1403px $white,  572px 1802px $white, 1158px  497px $white, 
                 313px  635px $white,  166px  172px $white,  899px  846px $white,  772px  713px $white, 1038px 1057px $white, 1462px  855px $white, 
                1835px 1311px $white,  155px   73px $white, 1137px  463px $white, 1847px  770px $white,  873px  529px $white, 1821px  911px $white, 
                 850px 1881px $white, 1942px 1643px $white, 1462px 1315px $white,  331px 1326px $white,  360px  203px $white,  765px   14px $white, 
                 147px  106px $white,  879px   88px $white,  981px  139px $white,  596px  181px $white, 1354px  577px $white, 1501px 1075px $white, 
                1778px 1902px $white, 1011px 1719px $white,  438px 1198px $white,  144px 1271px $white, 1739px  891px $white,  338px  542px $white, 
                 940px  804px $white,  277px  702px $white,  263px   86px $white,   82px 1541px $white, 1856px 1447px $white,  675px 1944px $white, 
                1047px  449px $white, 1541px  740px $white,  407px  101px $white,  525px   87px $white, 1447px 1678px $white, 1629px 1201px $white, 
                 932px  234px $white, 1186px  456px $white, 1898px 1676px $white, 1636px 1860px $white, 1380px  365px $white, 1075px 1219px $white, 
                1201px    6px $white,   84px 1713px $white, 1889px 1250px $white,  397px  307px $white, 1703px  690px $white,  653px 1700px $white, 
                1543px 1851px $white, 1987px  295px $white, 1740px 1196px $white, 1921px  985px $white, 1968px  294px $white, 1000px  869px $white, 
                1612px  208px $white, 1957px 1767px $white, 1558px 1402px $white, 1348px  839px $white, 1208px  750px $white, 1702px  234px $white, 
                 872px  176px $white,   71px  102px $white, 1334px 1026px $white, 1339px 1150px $white,  636px 1897px $white,  885px 1779px $white, 
                1742px   68px $white,   28px   57px $white, 1356px 1438px $white, 1265px 1918px $white, 1606px  839px $white, 1568px 1576px $white, 
                1650px 1440px $white,  498px 1417px $white, 1684px 1630px $white,  378px 1496px $white,  533px 1478px $white, 1422px  679px $white, 
                 628px  866px $white, 1564px 1527px $white, 1582px  369px $white,  949px  686px $white,   38px 1156px $white, 1453px  875px $white, 
                 830px   74px $white, 1822px  957px $white,  913px 1616px $white,  803px 1220px $white, 1531px 1277px $white,   86px 1560px $white, 
                 499px   20px $white, 1957px  503px $white, 1765px  442px $white, 1771px  358px $white,   61px  714px $white, 1672px 1993px $white, 
                1859px 1302px $white,  363px 1824px $white,  643px   48px $white, 1853px 1388px $white, 1907px 1724px $white,  977px 1863px $white, 
                 629px 1810px $white,  297px  260px $white,   94px  876px $white, 1196px  947px $white,  149px   99px $white, 1180px   50px $white, 
                 995px 1680px $white, 1773px 1312px $white,  919px  842px $white, 1646px  503px $white,   93px  613px $white,  353px  136px $white, 
                1344px  642px $white,  858px 1503px $white;
    animation: animStar 100s linear infinite;
}

#stars2:after {
    content: " ";
    position: absolute;
    top: 1250px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1481px  608px $white,  717px  101px $white,  890px 1721px $white, 1767px 1949px $white, 1864px 1216px $white, 1514px  849px $white, 
                 742px 1068px $white,  230px  663px $white, 1261px  828px $white, 1211px  710px $white,  111px 1322px $white,  483px 1159px $white, 
                1835px  314px $white,  251px 1900px $white, 1204px 1177px $white, 1550px 1256px $white,  386px 1732px $white, 1729px  142px $white, 
                  30px  994px $white,  488px 1637px $white,   77px  523px $white,  470px 1521px $white,  645px 1839px $white,  702px 1859px $white, 
                1582px  507px $white,  145px  445px $white, 1070px 1066px $white,  664px 1652px $white, 1530px  326px $white, 1820px 1922px $white, 
                 776px  273px $white, 1873px  135px $white, 1715px  789px $white, 1900px  989px $white, 1438px  508px $white, 1445px  893px $white, 
                 651px 1010px $white, 1250px 1777px $white, 1077px  509px $white, 1241px 1597px $white,  773px 1811px $white,   12px 1347px $white, 
                1805px 1939px $white,   53px 1847px $white,  662px 1130px $white,  902px  571px $white,  533px  393px $white, 1155px  435px $white, 
                1201px  882px $white,  709px  825px $white,  156px 1046px $white, 1805px  736px $white, 1035px  737px $white, 1695px  308px $white, 
                1888px 1334px $white,   97px  675px $white, 1168px  276px $white,  594px  701px $white, 1585px 1338px $white,  532px   34px $white, 
                1096px 1999px $white,    5px  867px $white, 1237px  976px $white,  618px 1314px $white, 1385px 1915px $white,  265px 1552px $white, 
                1347px 1123px $white, 1364px 1534px $white, 1817px 1559px $white, 1014px  456px $white, 1164px 1946px $white, 1600px  257px $white, 
                1726px 1826px $white,  258px 1207px $white,    6px 1718px $white,  611px 1403px $white,  572px 1802px $white, 1158px  497px $white, 
                 313px  635px $white,  166px  172px $white,  899px  846px $white,  772px  713px $white, 1038px 1057px $white, 1462px  855px $white, 
                1835px 1311px $white,  155px   73px $white, 1137px  463px $white, 1847px  770px $white,  873px  529px $white, 1821px  911px $white, 
                 850px 1881px $white, 1942px 1643px $white, 1462px 1315px $white,  331px 1326px $white,  360px  203px $white,  765px   14px $white, 
                 147px  106px $white,  879px   88px $white,  981px  139px $white,  596px  181px $white, 1354px  577px $white, 1501px 1075px $white, 
                1778px 1902px $white, 1011px 1719px $white,  438px 1198px $white,  144px 1271px $white, 1739px  891px $white,  338px  542px $white, 
                 940px  804px $white,  277px  702px $white,  263px   86px $white,   82px 1541px $white, 1856px 1447px $white,  675px 1944px $white, 
                1047px  449px $white, 1541px  740px $white,  407px  101px $white,  525px   87px $white, 1447px 1678px $white, 1629px 1201px $white, 
                 932px  234px $white, 1186px  456px $white, 1898px 1676px $white, 1636px 1860px $white, 1380px  365px $white, 1075px 1219px $white, 
                1201px    6px $white,   84px 1713px $white, 1889px 1250px $white,  397px  307px $white, 1703px  690px $white,  653px 1700px $white, 
                1543px 1851px $white, 1987px  295px $white, 1740px 1196px $white, 1921px  985px $white, 1968px  294px $white, 1000px  869px $white, 
                1612px  208px $white, 1957px 1767px $white, 1558px 1402px $white, 1348px  839px $white, 1208px  750px $white, 1702px  234px $white, 
                 872px  176px $white,   71px  102px $white, 1334px 1026px $white, 1339px 1150px $white,  636px 1897px $white,  885px 1779px $white, 
                1742px   68px $white,   28px   57px $white, 1356px 1438px $white, 1265px 1918px $white, 1606px  839px $white, 1568px 1576px $white, 
                1650px 1440px $white,  498px 1417px $white, 1684px 1630px $white,  378px 1496px $white,  533px 1478px $white, 1422px  679px $white, 
                 628px  866px $white, 1564px 1527px $white, 1582px  369px $white,  949px  686px $white,   38px 1156px $white, 1453px  875px $white, 
                 830px   74px $white, 1822px  957px $white,  913px 1616px $white,  803px 1220px $white, 1531px 1277px $white,   86px 1560px $white, 
                 499px   20px $white, 1957px  503px $white, 1765px  442px $white, 1771px  358px $white,   61px  714px $white, 1672px 1993px $white, 
                1859px 1302px $white,  363px 1824px $white,  643px   48px $white, 1853px 1388px $white, 1907px 1724px $white,  977px 1863px $white, 
                 629px 1810px $white,  297px  260px $white,   94px  876px $white, 1196px  947px $white,  149px   99px $white, 1180px   50px $white, 
                 995px 1680px $white, 1773px 1312px $white,  919px  842px $white, 1646px  503px $white,   93px  613px $white,  353px  136px $white, 
                1344px  642px $white,  858px 1503px $white;
}

#stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 1339px 1412px $white, 1176px  759px $white, 1532px  812px $white,  498px  320px $white, 1596px  344px $white, 1855px  782px $white, 
                 152px  820px $white, 1913px  188px $white,  843px  172px $white,  678px  798px $white,  299px 1455px $white, 1489px  923px $white, 
                 770px 1069px $white, 1172px 1661px $white,  760px  518px $white, 1680px 1730px $white, 1263px  382px $white, 1613px   43px $white, 
                1800px  565px $white, 1486px  866px $white, 1982px 1495px $white, 1426px 1899px $white, 1114px  653px $white,  145px  561px $white, 
                 205px  379px $white,   58px  485px $white,  837px  501px $white, 1607px  487px $white, 1806px  255px $white, 1173px 1431px $white, 
                1172px 1518px $white, 1020px 1316px $white, 1291px  623px $white,  354px  950px $white, 1805px   25px $white,   25px 1543px $white, 
                 486px 1618px $white, 1710px 1368px $white, 1739px 1421px $white, 1521px  511px $white, 1815px  202px $white,   10px 1383px $white, 
                1046px  415px $white, 1136px 1684px $white, 1627px 1957px $white,  299px 1425px $white,  654px  369px $white, 1969px  710px $white, 
                1332px 1855px $white,  570px 1889px $white,  234px  244px $white, 1874px 1073px $white, 1618px  204px $white,  306px  502px $white, 
                1985px 1362px $white,  379px 1318px $white,  549px  524px $white, 1244px 1682px $white, 1434px 1713px $white, 1030px 1588px $white, 
                1442px 1605px $white, 1062px   11px $white,  684px  137px $white, 1570px  472px $white, 1150px 1880px $white, 1460px 1332px $white, 
                 807px 1181px $white, 1318px 1217px $white,  128px  148px $white, 1337px  781px $white,  763px 1105px $white,  609px 1494px $white, 
                1448px  124px $white,  706px 1412px $white, 1152px  563px $white, 1197px 1968px $white,  786px 1468px $white,   11px 1399px $white, 
                1519px  655px $white, 1844px  639px $white, 1652px  274px $white, 1918px  248px $white,  618px  448px $white,   76px 1222px $white, 
                1199px  622px $white, 1566px  229px $white, 1191px  152px $white,  674px  288px $white, 1260px 1597px $white, 1713px  576px $white, 
                1147px 1670px $white, 1146px  608px $white,  698px 1846px $white,  313px 1146px $white, 1471px  914px $white,  441px  521px $white, 
                1166px 1128px $white, 1270px  290px $white, 1820px  130px $white, 1579px 1444px $white;
    animation: animStar 150s linear infinite;
}

#stars3:after {
    content: " ";
    position: absolute;
    top: 1250px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 1339px 1412px $white, 1176px  759px $white, 1532px  812px $white,  498px  320px $white, 1596px  344px $white, 1855px  782px $white, 
                 152px  820px $white, 1913px  188px $white,  843px  172px $white,  678px  798px $white,  299px 1455px $white, 1489px  923px $white, 
                 770px 1069px $white, 1172px 1661px $white,  760px  518px $white, 1680px 1730px $white, 1263px  382px $white, 1613px   43px $white, 
                1800px  565px $white, 1486px  866px $white, 1982px 1495px $white, 1426px 1899px $white, 1114px  653px $white,  145px  561px $white, 
                 205px  379px $white,   58px  485px $white,  837px  501px $white, 1607px  487px $white, 1806px  255px $white, 1173px 1431px $white, 
                1172px 1518px $white, 1020px 1316px $white, 1291px  623px $white,  354px  950px $white, 1805px   25px $white,   25px 1543px $white, 
                 486px 1618px $white, 1710px 1368px $white, 1739px 1421px $white, 1521px  511px $white, 1815px  202px $white,   10px 1383px $white, 
                1046px  415px $white, 1136px 1684px $white, 1627px 1957px $white,  299px 1425px $white,  654px  369px $white, 1969px  710px $white, 
                1332px 1855px $white,  570px 1889px $white,  234px  244px $white, 1874px 1073px $white, 1618px  204px $white,  306px  502px $white, 
                1985px 1362px $white,  379px 1318px $white,  549px  524px $white, 1244px 1682px $white, 1434px 1713px $white, 1030px 1588px $white, 
                1442px 1605px $white, 1062px   11px $white,  684px  137px $white, 1570px  472px $white, 1150px 1880px $white, 1460px 1332px $white, 
                 807px 1181px $white, 1318px 1217px $white,  128px  148px $white, 1337px  781px $white,  763px 1105px $white,  609px 1494px $white, 
                1448px  124px $white,  706px 1412px $white, 1152px  563px $white, 1197px 1968px $white,  786px 1468px $white,   11px 1399px $white, 
                1519px  655px $white, 1844px  639px $white, 1652px  274px $white, 1918px  248px $white,  618px  448px $white,   76px 1222px $white, 
                1199px  622px $white, 1566px  229px $white, 1191px  152px $white,  674px  288px $white, 1260px 1597px $white, 1713px  576px $white, 
                1147px 1670px $white, 1146px  608px $white,  698px 1846px $white,  313px 1146px $white, 1471px  914px $white,  441px  521px $white, 
                1166px 1128px $white, 1270px  290px $white, 1820px  130px $white, 1579px 1444px $white;
}

@keyframes animStar {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-2000px);
    }
}