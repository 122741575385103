@import '../_variables.scss';

section {
    border-radius: 50%;
    height: 80vh;
    width: 80vh;
    background-color: $black;
    position: fixed;
    top: 10vh;
    left: 10vh;
    animation: animHole 12s infinite alternate;
}

@keyframes animHole {
    0% {
    box-shadow: 0    0    2px  2px rgba(255, 255, 255, 0.7),
                4px -4px  8px  8px rgba(255, 0, 0, 0.5),
               -4px  4px  8px  8px rgba(0, 0, 255, 0.3),
                0    0   16px 16px rgba(255, 0, 255, 0.4),
               -6px  2px  8px  8px rgba(255, 0, 0, 0.4),
               -8px -4px  8px  8px rgba(0, 0, 255, 0.6),
                4px  8px 16px 16px rgba(255, 0, 255, 0.3),
               12px 40px  8px  8px rgba(0, 0, 255, 0.1);
    }
    50% {
    box-shadow: 0    0    2px  2px rgba(255, 255, 255, 0.7),
                6px  2px  8px  8px rgba(255, 0, 0, 0.5),
               -4px 12px  8px  8px rgba(0, 0, 255, 0.3),
                2px  0   -2px 16px rgba(255, 0, 255, 0.4),
               -6px  4px  8px  8px rgba(255, 0, 0, 0.4),
                8px -8px  8px  8px rgba(0, 0, 255, 0.6),
               -4px -8px 16px 16px rgba(255, 0, 255, 0.3),
               16px 10px  8px  8px rgba(0, 0, 255, 0.1);
    }
    100% {
    box-shadow: 0    0    2px  2px rgba(255, 255, 255, 0.7),
                2px  4px  8px  8px rgba(255, 0, 0, 0.5),
               -2px -2px  8px  8px rgba(0, 0, 255, 0.5),
               -2px  0   16px 16px rgba(255, 0, 255, 0.4),
                6px  6px  8px  8px rgba(255, 0, 0, 0.4),
               -8px -4px  8px  8px rgba(0, 0, 255, 0.4),
               12px -8px 16px 16px rgba(255, 0, 255, 0.3),
               24px 20px  8px  8px rgba(0, 0, 255, 0.3);
    }
}


// Mobile friendly
@media screen and (max-width: 1200px) {
    section {
        height: 10vh;
        width: 10vh;
        top: 12vh;
        left: 4vh;
    }
}