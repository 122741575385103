@import '../_variables.scss';

ul {
    list-style-type: none;
    margin-right: -20px;
    padding-right: 10px;

	li {
	    padding-right: 10px;

	    &:after {
	        position: relative;
	        left: 10px;
	        content: "\25C1";
	        color: $red;
	        padding-left: 10px;
	    }

	    &:hover:after {
	        left: 20px;
	    }
	    
		button {
		    color: $white;
		    background: transparent;
		    border: 0;
		    font-family: 'Squared';
		    letter-spacing: 2px;
		    font-size: 20px;
		    outline: 0;
		    cursor: pointer;
		}
	}
}